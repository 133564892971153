<template>
  <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white pt-sm-3 pt-md-5" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <sidebar-toggle-button @click.native="showSidebar">
                
            </sidebar-toggle-button>
            
            <router-link class="navbar-brand" to="/">
                <img src="@/assets/images/logo/hn_liever_logo.svg" class="d-none d-md-block" right height="100px" alt="Happy Nurse" />
                <img src="@/assets/images/logo/hn_liever_mobile_logo.svg" class="d-md-none d-sm-block" right height="100px" alt="Happy Nurse" />
            </router-link>

            <slot name="mobile-right">
                <ul class="nav align-items-center d-md-none"></ul>
            </slot>

            <slot></slot>

            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-6 collapse-brand">
                            <router-link to="/">
                                <img src="@/assets/images/logo/hn_liever_mobile_logo.svg" right height="100px" alt="Happy Nurse" />
                            </router-link>
                        </div>
                        <div class="col-6 collapse-close d-flex flex-row-reverse align-items-center">
                            <sidebar-toggle-button @click.native="closeSidebar"></sidebar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>
                
            </div>
          </div>
    </nav>
</template>
<script>
import SidebarToggleButton from './SidebarToggleButton'
export default {
  name: 'sidebar',
  props: {
    title: {
      type: String,
      default: 'Creative Tim',
      description: 'Sidebar title'
    },
    shortTitle: {
      type: String,
      default: 'CT',
      description: 'Sidebar short title'
    },
    logo: {
      type: String,
      default: 'https://demos.creative-tim.com/vue-argon-dashboard-pro/img/brand/green.png',
      description: 'Sidebar app logo'
    },
    backgroundColor: {
      type: String,
      default: 'vue',
      validator: value => {
        let acceptedValues = [
          '',
          'vue',
          'blue',
          'green',
          'orange',
          'red',
          'primary'
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description:
        'Sidebar background color (vue|blue|green|orange|red|primary)'
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
      description:
        "List of sidebar links as an array if you don't want to use components for these."
    },
    autoClose: {
      type: Boolean,
      default: true,
      description:
        'Whether sidebar should autoclose on mobile when clicking an item'
    }
  },

  components: {
      SidebarToggleButton
    },


  provide() {
    return {
      autoClose: this.autoClose
    };
  },
  methods: {
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true)
    },
    closeSidebar() {
        this.$sidebar.displaySidebar(false)
      },
  },
  mounted() {
    this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth
    this.minimizeSidebar()
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  }
};
</script>
