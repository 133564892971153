<template>
  <div class="wrapper">
  
    <!--<notifications></notifications>-->
    <!--<side-bar>
      <template slot="links">
        <sidebar-item :link="{ name: 'Dashboard', icon: 'ni ni-chart-pie-35', path: '/dashboard'}" />
        <sidebar-item :link="{ name: 'Acties', icon: 'ni ni-bullet-list-67', path: '/to-do'}" />
        
        <hr class="my-3 app-local-line">

        <sidebar-item :link="{ name: 'Sollicitanten', icon: 'ni ni-single-copy-04', path: '/people/applicants/list'}" />
        <sidebar-item :link="{ name: 'Uitzendkrachten', icon: 'ni ni-single-02', path: '/people/employees/list'}" />
      
        <hr class="my-3 app-local-line">

        <sidebar-item :link="{ name: 'Prospects', icon: 'ni ni-zoom-split-in', path: '/clients/prospects'}" />
        <sidebar-item :link="{ name: 'Klanten', icon: 'ni ni-building', path: '/clients/list'}" />
        
      
        <hr class="my-3 app-local-line">
        
        <sidebar-item :link="{ name: 'Planning', icon: 'ni ni-calendar-grid-58', path: '/planning'}" />
      
        <hr class="my-3 app-local-line" v-if="userRole == 1">
      
        <sidebar-item v-if="userRole == 1" :link="{ name: 'Instellingen', icon: 'ni ni-settings-gear-65', path: '/settings/settings'}" />
      </template>
    </side-bar>-->
    <div class="main-content">
      <!--<dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>-->

      <div @click="$sidebar.displaySidebar(false)" style="min-height: calc(100vh - 160px); padding: 16px;">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  //import DashboardNavbar from './DashboardNavbar.vue';
  import ContentFooter from './ContentFooter.vue';
  import { FadeTransition } from 'vue2-transitions';

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    components: {
      //DashboardNavbar,
      ContentFooter,
      FadeTransition
    },

    created(){
      //this.userRole = JSON.parse(localStorage.getItem('user')).id_user_const_role
    },

    data(){
      return{
        userRole: null,
        icons: {
                people: require('../../assets/images/svg/menu_people.svg')
        }
      }
    },

    methods: {
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      }
    },
    mounted() {
      this.initScrollbar()
    }
  };
</script>
<style lang="scss">
.app-local-line {
    min-width: 80%;
  }
</style>
