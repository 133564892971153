
const dictionary = {
  //DOCUMENTS
  documents: {
    left_sidebar_header: 'Formulier voor documentondertekening',
    left_sidebar_step_1: 'Document',
    left_sidebar_step_2: 'Document ondertekenen',
    step1_header: 'Document',
    step2_header: 'Document ondertekenen',
    cancel_sign_confirmation: 'Weet je zeker dat je het document wilt annuleren?',
    cancel_document: 'Document afwijzen',
    sign_document: 'Document ondertekenen',
    send_document: 'Document verzenden',
    cancel_document_confirmation: 'Uw document is geannuleerd.'
  },

  //CONTRACTS
  contracts: {
    left_sidebar_header: 'Overeenkomst ondertekeningsformulier',
    left_sidebar_step_1: 'Overeenkomst',
    left_sidebar_step_2: 'Overeenkomst ondertekenen',
    step1_header: 'Overeenkomst',
    step2_header: 'Overeenkomst ondertekenen',
    cancel_sign_confirmation: 'Weet je zeker dat je het contract wilt annuleren?',
    cancel_document: 'Overeenkomst afwijzen',
    sign_document: 'Overeenkomst ondertekenen',
    send_document: 'Overeenkomst verzenden',
    cancel_document_confirmation: 'Uw contract is geannuleerd.'
  },

  //PERSON FORM
  person_form: {
    left_sidebar_header: ''
  },

  //CLIENT FORM
  client_form: {
    left_sidebar_header: ''
  }

}

export default dictionary