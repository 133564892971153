import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';
import MainLayout from '@/views/Layout/MainLayout.vue';
import Page227 from '@/views/227Page.vue';
import Page228 from '@/views/228Page.vue';
import Page229 from '@/views/229Page.vue';
import Page404 from '@/views/404Page.vue';
import Page500 from '@/views/500Page.vue';

let authPages = {
  path: '*',
  component: AuthLayout,
  name: 'Authentication',
  children: [    
    { 
      path: '*',
      name: '404-error-page',
      component: Page404
    },
    {
      path: "/blocked-form",
      name: "227-status-page",
      component: Page227
    },
    {
      path: "/completed-form",
      name: "228-status-page",
      component: Page228
    },
    {
      path: "/signed-document",
      name: "229-status-page",
      component: Page229
    },
    {
      path: "/500-error",
      name: "500-error-page",
      component: Page500
    }
  ]
};

let peoplePages = {
  path: '*',
  component: MainLayout,
  name: 'Main layout people',
  children: [    
    { 
      path: '/people/data-wizard/:token([0-9a-z]{13})',
      name: 'people-data-wizard',
      component: () => import('@app/People/DataWizard/PeopleDataWizard.vue')
    },
    {
      path: '/people/document-sign/:token([0-9a-z]{13})',
      name: 'people-document-sign',
      component: () => import('@app/People/DocumentSign/PeopleDocumentSign.vue')
    }
  ]
};

let clientPages = {
  path: '*',
  component: MainLayout,
  name: 'Main layout client',
  children: [    
    { 
      path: '/client/data-wizard/:token([0-9a-z]{13})',
      name: 'client-data-wizard',
      component: () => import('@app/Clients/DataWizard/ClientsDataWizard.vue')
    },
    {
      path: '/client/document-sign/:token([0-9a-z]{13})',
      name: 'client-document-sign',
      component: () => import('@app/Clients/DocumentSign/ClientsDocumentSign.vue')
    }
  ]
};

const routes = [
   peoplePages,
   clientPages,
   authPages
];

export default routes;
