<template>
     <b-row>
          <b-col cols="12">
               <b-form-group :label="label" :content-cols-md="label != null && label.length > 0 ? 8 : 12" :label-cols-md="label != null && label.length > 0 ? 4 : 0" label-class="d-flex align-items-center app-input-label">
                    <b-row>
                          <b-col v-for="(column, index) in slots" v-bind:key="index" :cols="column"  v-bind:class="{'pl-1' : setLeftMargin(index), 'pr-1' : setRightMargin(index)}">
                              <slot v-if="slots.length > 1" :name='"col-" + (index + 1)'></slot>                              
                              <slot v-else></slot>                                   
                          </b-col>
                    </b-row>
                    <b-row v-if="customError">
                         <b-col cols="12">
                             <slot name='custom-error'></slot>
                         </b-col>
                    </b-row>                  
               </b-form-group>
          </b-col>
     </b-row>
</template>

<script>

export default {
  name: 'AppRowLeftLabel',
  props: {
     label: { type: String },
     customError: { type: Boolean, default: false},
     slots: {
               type: Array, 
               default() { return [12]; }
          },
     isMarginBetween: {type: Boolean, default: true}
  },

  methods: {
     setRightMargin(index) {
         if(this.slots.length > 1 && (this.slots.length - 1 != index) && this.isMarginBetween) {
              return true;
         } else {
              return false;
         }
     },

     setLeftMargin(index) { 
         
         if(this.slots.length > 1 && index > 0 && this.isMarginBetween) {
              return true;
         } else {
              return false;
         }
     }
  },
}
</script>
<style scoped>
.app-local-max-height {
    max-height: 30px;
}
</style>
