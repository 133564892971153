<template>
    <div>

        <div style="display: flex;">      


        <b-link v-if="settingsVisible === true" id="popover-settings" class="mr-2" style="color: #98A2B3; cursor:pointer;outline: none !important;">
            <b-icon icon="gear-fill" style="width:18px; height:18px" />
        </b-link>

        <div> 
            <b-link v-if="!loadingList" style="color: #98A2B3; cursor:pointer; outline:none !important;" @click="refreshList">
                <b-icon icon="arrow-repeat" style="width:22px; height:22px" />
            </b-link>

            <b-spinner v-else style="width:19px; height:19px; margin-left:3px; margin-top:5px" small></b-spinner>       
        </div>

        <b-popover v-if="settingsVisible === true" target="popover-settings" ref="popover" no-fade placement="auto" triggers="focus" @show="showPopover">
            <template #title>
                Tabelopties
            </template>
                
                <b-card no-body class="mb-2" >
                    <b-card-body style="padding: 10px;">
                        <b-form-group label="Rijn per pagina">
                            <b-form-checkbox-group id="perpagina-group" v-model="perPage" @change="checkChanged()">
                                <b-form-checkbox value="5" size="sm"><span class="app-documents-item">5</span></b-form-checkbox>
                                <b-form-checkbox value="10" size="sm"><span class="app-documents-item">10</span></b-form-checkbox>
                                <b-form-checkbox value="20" size="sm"><span class="app-documents-item">20</span></b-form-checkbox>
                                <b-form-checkbox value="50" size="sm"><span class="app-documents-item">50</span></b-form-checkbox>
                                <b-form-checkbox value="100" size="sm"><span class="app-documents-item">100</span></b-form-checkbox>
                            </b-form-checkbox-group>
                        </b-form-group>                        
                    </b-card-body>
                </b-card>
                
           </b-popover>
           
    </div>  
        
    </div>  
</template>
<script>
export default {
    props: ["filters", 'loadingList', "items", "settingsVisible"],

    data() {
        return {       
           perPage: [0]
        };
    },

    methods: {
        checkChanged(event) {
          var nFilters = this.filters;
           if(this.perPage.length > 0) {
               if (this.filters.page > 1) {
                nFilters.page = 1;
            } else {
                nFilters.perPage = this.perPage[1]; 
                this.perPage = [this.filters.perPage];               
                nFilters.changed ++;
            }
            
        } else {
            this.perPage = [this.filters.perPage]; 
        }
         
        this.$refs.popover.$emit('close'); 

           
       },
       
       showPopover() {
           this.perPage = [this.filters.perPage];
        },
        
       refreshList() {
           var nItems = this.items;
           nItems.refresh ++;
       } 

    },

};
</script>


<style>
.app-dropdown-header button {
    padding: 0;
    margin-left: -4px;
    margin-bottom: -2px;
}
.custom-control:focus {
    outline: none !important;
}
</style>