<template>
    <div>
        <validation-provider v-slot="{ errors}" :rules="validatorRules" :name="validatorName"  :custom-messages="validatorCustomMessage">
            <label v-if="label" class="app-input-top-label">{{label}}</label>
            <br v-if="additionalInfo" /><small v-if="additionalInfo" class="text-muted">{{additionalInfo}}</small>
            <v-select :multiple="multipleSelection" :searchable="true" :calculatePosition="calculatePosition" label="name" v-model="cValue" :append-to-body="appendToBody" :selectable="selectable" attach="#vs3__combobox"  :reduce="reduceValue ? (status)=>status.value: (status)=>status" :loading="loading" :clearable="clearable==true" :selectOnTab="true" :options="items" @open="onOpen" :disabled="disable" :dropdownOverlay="true" :class="{'app-select-filled': cValue != null && (typeof cValue != 'object'  || (cValue.name != '' && cValue.name != ' ')), 'app-select-error': (errors.length > 0 && errors[0] != '' && cValue == null)}">
                <template v-slot:selected-option="option">  
                    <span v-overflow-tooltip>{{typeof option == 'object' ? option.name : option }}</span>
                </template>
                <template v-slot:option="option">  
                    <span v-overflow-tooltip v-if="!customBody">{{typeof option == 'object' ? option.name : option }}</span>
                     <slot name="option" v-bind:data="option" v-else/>
                </template>
                <template slot="no-options">
                    Geen resultaten gevonden
                </template> 
                <template #spinner="{ loading }">
                    <div v-if="loading" class="d-flex justify-content-center align-items-center">
                        <b-spinner class=" app-spinner-size"></b-spinner>
                    </div>
                </template> 
            </v-select>
            
            <app-input class='app-big-error-info' type="hidden"  v-model="cValue"/>
            <small v-if="(errors.length > 0 && errors[0] != '' && cValue == null)" class="ml-1 text-error">{{ errors[0] }}</small> 
            
        </validation-provider> 
    </div>        
</template>
<script>

import vSelect from "vue-select";
import axios from "@axios";
export default {
    name: 'AppSelect',

    components: {
        vSelect
    },

    props: {
        value:{},
        args: { type: Object },
        refreshIndex: { type: Number },
        type: { type: String },
        options: {type: Array},
        multiple: { type: Boolean },
        disable: { type: Boolean },
        clearable: { type: Boolean },
        validatorName: { type: String },
        validatorRules: { type: String },
        validatorCustomMessage: { type: Object },
        label: {type: String},
        reduceValue: {type: Boolean, default: false},
        additionalInfo: {type: String},
        customBody: {type: Boolean, default: false},
        itemsCount: {type: Number, default: -1},
        appendToBody: {type: Boolean, default: false},
        calculatePosition: {},
        selectable: {}
    },

     created(){
         if(this.options != null){

             this.items = this.options;

             if(!this.reduceValue && typeof this.cValue != 'object' && typeof this.items != null && this.items.length> 0 &&typeof this.items[0] == 'object' ){
                 this.cValue = this.items.find((item)=> item.value == this.value);
             }else if(!this.reduceValue){
                 this.cValue = this.value;
             }
         }
         
    },

    watch: {
        "refreshIndex": {
            handler: function(val) {
                this.getData();
            },
        },

        "options": {
            handler: function(val) {
                if(val != null){

                    this.items = val;
                    //this.setValue();

                }
            },
        }
    },
    
    computed: {
    // a computed getter
        multipleSelection: function () {
            if (this.multiple !== undefined && this.multiple !== null) {
                return this.multiple;
            }
            return false
        },

        cItemsCount: {
            get(){ return this.items.length; },
            set(value) { this.$emit('update:itemsCount', value); }
        },

        cValue: {
            get(){ return this.value; },
            set(value) { this.$emit('input', value); }
        }
    },



    data() {
        return {
          
          items: [],
          loading: false,
          refresh: 0,
          parameters: this.args,

        };
    },

    methods: {
        onOpen() {
            if(this.items.length == 0 && this.options == null) {
                this.getData();
            }
        },

        getData() {
            this.loading = true;
            axios
                .post("core/select-item/getList", {
                    type: this.type,
                    parameters: JSON.stringify(this.parameters),
                })
                .then((res) => {
                    this.items = res.data;
                    if(this.items.length == 1){
                        //this.cValue  = {value: res.data[0].value, name: res.data[0].name}
                        this.cValue = res.data[0];
                        //this.$emit('input', res.data[0]);
                    }else{
                      // this.selectedItem = this.value; 
                    }
                    
                    this.cItemsCount = res.data.length; 
                })
                .catch((error) => {
                    console.error(`error during request: ${error}`);
                })
                .finally(() => {
                   this.loading = false;
                });
        }
    }

};
</script>

<style>
</style>