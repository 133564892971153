<template>
  <div>
    <!-- Header -->
    <div class="header bg-secondary py-6 py-lg-7 pt-lg-8">
     
      <div class="separator separator-bottom separator-skew zindex-100">
        <svg x="0" y="0" viewBox="0 0 3560 100" preserveAspectRatio="none" version="1.1"
             xmlns="http://www.w3.org/2000/svg">
          <polygon class="fill-light" points="3560 0 3560 100 0 100"></polygon>
        </svg>
      </div>
    </div>
    <!-- Page content -->
    <b-container class="mt--8 pb-5">
      <b-row class="justify-content-center">
        <b-col lg="10" md="6">
          <b-card no-body class="bg-white border-0 mb-0">
            
            <b-card-body class="px-lg-5 py-lg-5">
              
              <div class="w-100 text-center">
                <h2 class="mb-1">
                  Jij bent niet geautoriseerd!
                </h2>

                <p class="mb-2">
                  U heeft geen toestemming om deze pagina te openen. Ga naar huis!
                </p>

                <div>
                    <img class="pr-2 app-image-404" src="@/assets/images/icons/primary_warning.svg" alt="404"  />
                    <span class="align-middle app-main-text-404">404</span>
                </div>

              </div>
            </b-card-body>
          </b-card>
          
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  components: {
    
  },
  data() {
    return {
    }
  },
  
}
</script>

<style lang="scss">
</style>
