<template>
  <footer id="footer" class="footer px-4 mx-2 app-footer-content">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col lg="6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{year}} <a href="https://www.happynurse.nl" class="font-weight-bold ml-1" target="_blank">HappyNurse</a><span> Alle rechten voorbehouden</span>
        </div>
      </b-col>
      <b-col cols="auto" end>
        <router-link to="/BuildLog">
            <span style="color:#cbcbcb;">build KKY 12.07.2022 09:45 (v.1.145)</span> 
        </router-link>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style>
.app-footer-content{
  color: #6e6b7b;
  font-size: 14px !important;
}</style>
