<template>
    
        <b-overlay :show="loading" :opacity="0.6">
            <b-row class="app-card-header" v-if="isTopBar" v-bind:class="{ 'mb-2': isTopHead==true }">
                
                <b-col start>
                    <div class="app-card-tittle app-local-title-height" v-overflow-tooltip  v-if="isTitle">
                        <div v-if="title.length > 0" v-overflow-tooltip> {{title}} <span v-if="!loading && isTitleTotal">({{ items.total }})</span></div>
                        <slot name="view-title"></slot>    
                    </div>
                </b-col>

                <b-col v-for="(column, index) in topFiltersCount" v-bind:key="index" cols="auto" class="app-local-title-height" end>
                    <slot :name='"filter-col-" + (index + 1)'></slot>                                  
                </b-col>
                <b-col class="app-input-applicant-filter-width app-local-title-height pl-0" v-if="isSearch">
                    <b-form-input type="text" v-model="cFilters.search" :lazy="true" class="form-control app-input" :class="{'app-input-filled': filters.search.length > 0}" placeholder="Zoeken" size="sm" />
                </b-col>
                <b-col class="app-input-employee-filter-width app-local-title-height pl-0" end v-if="isFilterOptions">
                    <app-select v-model="cFilters.list_type" reduceValue customBody :clearable="false" :selectOnTab="true" :options="cFilterOptions" :selectable="(item) => item.value > 0" >
                        <template v-slot:option="option">
                        <hr v-if="option.data.value < 0" class="app-search-modal-separator">    
                        <span v-else>{{ option.data.name }}</span>
                        </template>
                    </app-select>  
                </b-col>
                <b-col cols="auto" end class="d-flex align-items-center app-local-title-height pl-0" v-if="isSettings">
                    <app-table-settings :filters.sync="cFilters" :loadingList="loading" :items="items"/>  
                </b-col>
            </b-row>
            <b-row >
                <b-col cols="12">
                     <b-table responsive small :class="customClass" :hover="isRowHover" select-mode="single" :fields="visibleFields" :items="items.list" ref="selectableTable" :selectable="isSelectable" @row-selected="onRowSelected" @row-clicked="onRowClicked" @row-hovered="onRowHovered" show-empty>
                        <template #empty>
                            <div class="d-flex justify-content-center align-items-center app-table-no-records">
                                Er zijn geen records om weer te geven
                            </div>
                        </template>   

                        <template #thead-top="data">
                            <slot name="thead-top" v-bind:data="data"></slot>
                        </template>

                         <template v-slot:cell()="data">
                            <span v-overflow-tooltip >{{ data.value}}</span>
                        </template>                  

                        <template #head()="data">
                            <app-column-filter :label="data.label" :fieldName="data.column" :sortingObject="cFilters.sorting" :filteringObject="data.field.filtering ? cFilters.filtering[data.field.filtering_name] : ''" v-if="data.field.sortable != false"/>      
                            <span v-else v-overflow-tooltip>{{data.label}}</span>                     
                        </template>
                       
                        <template v-slot:[`head(${column})`]="data" v-for="column in customColumnsHeaders">
                            <slot :name="'head_'+column" v-bind:data="data"></slot>
                        </template>

                        <template v-slot:[`cell(${column})`]="data" v-for="column in customColumns">
                            <slot :name="column" v-bind:data="data"></slot>
                        </template>

                        <template #row-details = "row" >
                            <slot name="row-details" v-bind:data="row"></slot>
                        </template>
                        

                        <template #cell(more)="data" v-if="isDropdownMenu">
                            <b-dropdown  variant="link" no-caret right boundary="window">
                                <template #button-content>
                                    <b-icon icon="three-dots-vertical" class="text-body"></b-icon>
                                </template>
                                
                                <slot name="menu-items" v-bind:data="data">                                  
                                    
                                </slot>                                
                            </b-dropdown>
                        </template>
                        
                     </b-table>    
                </b-col>
            </b-row>
            <b-row class="d-flex align-items-center mt-2" v-if="isBottomBar">
                <b-col>
                    <slot name="buttons"></slot>                    
                </b-col>
                <b-col v-if="removeAll" cols="auto" end>
                    <app-column-filter-remove-all :filters.sync="cFilters"/>
                </b-col>
                
                <b-col cols="auto" end v-if="items.total > filters.per_page">                        
                    <b-pagination first-number last-number pills align="right" v-model="cFilters.page" :total-rows="items.total" :per-page="cFilters.per_page" size="sm"/>
                </b-col>
                <b-col v-if="items.total > 10" cols="auto" class="p-0 mr-3 app-local-perpage-min-width">
                    <app-select v-model="cFilters.per_page" :clearable="false" :selectOnTab="true" :options="[10, 20, 50, 100]" @input="perPageChanged"/> 
                </b-col>
            </b-row>

            <template #overlay>
                <div></div>
            </template>

        </b-overlay>

    </template>

<script>

import AppTableSettings from '@core/components/Table/AppTableSettings.vue';
import AppColumnFilterRemoveAll from '@core/components/Table/AppColumnFilterRemoveAll.vue';
import AppColumnFilter from '@core/components/Table/AppColumnFilter.vue';

export default {
    components: {
      AppTableSettings,
      AppColumnFilterRemoveAll,  
      AppColumnFilter,
    },

    name: 'AppViewTable',

    computed: {
        cFilters: {
            get() { return this.filters },
            set(value) { this.$emit('update:filters', value) }
        },

        cFilterOptions: {
            get() { return this.filterOptions },
            set(value) { this.$emit('update:filterOptions', value) }
        },
        
        visibleFields() {
            var return_fields = this.fields.filter(field => field.visible);
            if (this.isMenuColumn) {
                return_fields.push({ key: "more", label: "", tdClass: "p-0", visible: true, filtering: false});
            }

            return return_fields; 
        },
    },

    props: {
        title: { type: String },
        isTopBar: { type: Boolean, default: true},
        isTopHead: { type: Boolean, default: false},
        isBottomBar: { type: Boolean, default: true},
        isSettings: { type: Boolean, default: true},
        isTitleTotal: { type: Boolean, default: false},     
        isTitle: { type: Boolean, default: true},
        isDropdownMenu: { type: Boolean, default: true},
        isRowHover: { type: Boolean, default: true},
        isSelectable: { type: Boolean, default: true},
        isFilterOptions: { type: Boolean, default: false},
        isSearch: { type: Boolean, default: false},
        isMenuColumn: { type: Boolean, default: true},
        loading: { type: Boolean, default: false},
        custom: { type: Boolean, default: false},
        removeAll: {type: Boolean, default: true},
        topFiltersCount: {
                type: Number, 
                default() { return 0; }
            },
        filters: {},
        items: {},
        fields: {},
        filterOptions: {},
        customColumns: {type: Array, default: () => []},
        customClass:{type: String, default:""},
        customColumnsHeaders: {type: Array, default: () => []},
    },

    methods: {
        onRowSelected(event) {
        this.$emit('onRowSelected', event);
        },
        onRowClicked(event) {
        this.$emit('onRowClicked', event);
        },
        onRowHovered(event) {
            this.$emit('onRowHovered', event);
        },

        perPageChanged() {
            this.cFilters.changed++;
        }
        
    },
}
</script>
<style scoped>
.app-local-max-height {
    max-height: 30px;
}

.app-local-perpage-min-width {
    min-width: 80px;
}

.app-local-table-min-height {
    min-height: 150px;
}


.app-local-title-height{
    min-height: 30px;
}
</style>
