<template>
    <div v-if="isVisible">
        <app-button type="cancel" @click="removeAll">
             Alle filters verwijderen
        </app-button>            
    </div>  
</template>
<script>
export default {
    props: ["filters"],

    computed: {
         isVisible () {
             var filtersCount = 0;
             
            if(this.filters != undefined && this.filters.choosed_date != null){
                filtersCount++;
            }

            if(this.filters != undefined && this.filters.week_dates != null){
                filtersCount++;
            }

             if (this.filters != undefined && this.filters.search != null && this.filters.search.toString().length > 0) {
                 filtersCount++;
             }

             if (this.filters != undefined && this.filters.sorting != null && this.filters.sorting.field_name != null  && this.filters.sorting.sort_direction !== 0) {
                 filtersCount++;
            }
            if(this.filters != undefined && this.filters.filtering != null){
                Object.values(this.filters.filtering).forEach(function(fValues){
                    if (fValues.values.length > 0) {
                        filtersCount++;
                    }
                });
             }
 
             if (filtersCount > 0) {
                 return true;
             } else {
                 return false;
             }
        }  
    },

    data() {
        return {       
        };
    },

    methods: {
        
        removeAll() {

            var nFilters = this.filters;
            if(this.filters.filtering != null){
                Object.values(this.filters.filtering).forEach(function(fValues){
                    if (fValues.values.length > 0) {
                        fValues.values = [];
                    }
                });
            }
            nFilters.choosed_date = null;
            nFilters.week_dates = null;
            nFilters.sorting.field_name = null;
            nFilters.sorting.sort_direction = 0;

            if (this.filters.search != null && this.filters.search.toString().length > 0) {
                 nFilters.search = "";
            } else {
                nFilters.changed ++;
            }
                        
        },
    },
};
</script>

<style>
.app-spinner-size {
    max-width:20px;
    max-height:20px;
}

.popover {
    min-width: 200px;
}

.popover .arrow {
    display: none !important;
}
.custom-control:focus {
    outline: none !important;
}
</style>