  const TranslatePlugin = {
    install(Vue, options) {
        console.log(options);
      Vue.prototype.$translate = (key) => {
        return key.split('.').reduce((o, i) => {
          if (o) return o[i]
        }, options)
      }
    }
  };
  
  export default TranslatePlugin;